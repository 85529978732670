import { render, staticRenderFns } from "./ClientSingle.vue?vue&type=template&id=1eb5dfad&"
import script from "../components/ClientSingle.js?vue&type=script&lang=js&"
export * from "../components/ClientSingle.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/samedia2/idriveadmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1eb5dfad')) {
      api.createRecord('1eb5dfad', component.options)
    } else {
      api.reload('1eb5dfad', component.options)
    }
    module.hot.accept("./ClientSingle.vue?vue&type=template&id=1eb5dfad&", function () {
      api.rerender('1eb5dfad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ClientSingle.vue"
export default component.exports